import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerOne from "../../components/banner/BannerOne";
import imgElement from "../../assets/images/bg/elemento-horizontal.png"
import AboutExperience from "../../components/about/AboutExperience";
import StepsExperience from "../../components/steps/StepsExperience";
import FeatureExperience from "../../components/feature/FeatureExperience";
import OrientationExperience from "../../components/orientation/OrientationExperience";
import InvestimentExperience from "../../components/investiment/InvestimentExperience";
import QuestionsExperience from "../../components/questions/QuestinsExperience";


const questions = [
    [
        {
            "pergunta": "O que é o programa 'Experience Hunting' da Academy Abroad?",
            "resposta": "O 'Experience Hunting' é um programa projetado para profissionais e estudantes que possuem o objetivo de estudar, trabalhar ou se especializar no exterior. Ele oferece orientação personalizada, acesso a oportunidades internacionais, estágios, pesquisa e trabalho em empresas e hospitais no exterior, além de ajudar a construir um currículo forte para o mercado internacional."
        },
        {
            "pergunta": "Como funciona o programa?",
            "resposta": "O programa 'Experience Hunting' é dividido em cinco etapas, incluindo três encontros online com o Rafael Kenji, que guiará você em todo o processo, e dois encontros com Eduardo Lobo, ambos profissionais com passagem por Harvard. A Academy Abroad analisa seu perfil e fornece oportunidades personalizadas, ajudando na construção e aprimoramento do seu currículo, para que possam juntos construir o plano de desenvolvimento e captação de oportunidades."
        },
        {
            "pergunta": "Quem são Rafael Kenji e Eduardo Lobo?",
            "resposta": "Rafael Kenji é médico com passagem por Harvard, fundador da Academy Abroad e CEO da FHE Ventures e HealthAngels Venture Builder, fundos de investimento em formato de Venture Builder. Ele é especialista em inovação. Eduardo Lobo também é médico, pesquisador com passagem pela Harvard Medical School, com artigos publicados em revistas internacionais como a Nature. Eduardo também é gestor e investidor de startups, atuando com inovação, e é coordenador do Boston Healthcare Innovation Program."
        },
        {
            "pergunta": "Quanto custa o programa?",
            "resposta": "O programa tem um valor de investimento de R$4.000,00 ou então 12 parcelas de R$360,00 no cartão de crédito, com a contratação feita diretamente na plataforma."
        },
        {
            "pergunta": "Como faço para me inscrever no programa?",
            "resposta": "Para se inscrever no programa 'Experience Hunting' e começar sua jornada rumo ao sucesso internacional, entre em contato conosco pelo nosso site ou pelo e-mail plataforma@academyabroad.com.br. O contato também pode ser feito pelo Whatsapp (31) 99561-3002."
        },
        {
            "pergunta": "Qual é a duração do programa?",
            "resposta": "O programa tem a duração de até 3 meses, com, em média, um encontro online por mês e entregas recorrentes ao longo desse período. Cada caso é avaliado de forma individual para determinar a frequência dos encontros e das entregas."
        },
        {
            "pergunta": "Que tipo de suporte e orientação receberei durante o programa?",
            "resposta": "Durante o programa, você terá orientação direta do Rafael Kenji, que te guiará em cada etapa, além da participação do Eduardo Lobo. Além disso, nossa equipe estará disponível para responder a todas as suas perguntas e fornecer suporte contínuo."
        },
        {
            "pergunta": "Como o programa pode beneficiar minha carreira?",
            "resposta": "O programa 'Experience Hunting' pode abrir portas para oportunidades de estudo, trabalho e pesquisa no exterior, expandindo sua carreira, com grandes cases de sucesso já realizados. Além disso, ele ajuda a construir um currículo forte, tornando você mais competitivo no mercado internacional."
        },
        {
            "pergunta": "O programa é adequado para estudantes de graduação e profissionais de todas as áreas?",
            "resposta": "Sim, o programa é projetado para atender às necessidades de estudantes de graduação e profissionais de todas as áreas que desejam seguir uma carreira internacional. Embora frequentemente realizado por acadêmicos de medicina interessados em intercâmbio em Hospitais de Harvard, o programa não é exclusivo para a área da saúde."
        },
        {
            "pergunta": "Como posso entrar em contato com a Academy Abroad para mais informações?",
            "resposta": "Você pode entrar em contato conosco pelo nosso site, pelo e-mail plataforma@academyabroad.com.br ou pelo Whatsapp (31) 99561-3002. Também estamos disponíveis no Instagram @academy.abroad para responder a todas as suas perguntas e fornecer informações detalhadas sobre o programa."
        }
    ]    
]


const ExperienceHunting = () => {
    return(
        <>
            <SEO title={"Experience Hunting"} />
            <HeaderTwo styles="header-style-2" />
            <BannerOne 
                title="<h3 class='text-white'>Conquiste oportunidades de carreira e estudos no exterior com o Programa Experience Hunting</h3>"
                description="Programa de mentorias para análise de perfil, estruturação de currículo e desenvolvimento de estratégias para conquistar as principais oportunidades no exterior."
                bg="bg-experience"
                isButton={true}
                element={imgElement}
            />
            <AboutExperience />
            <StepsExperience />
            <FeatureExperience />
            <OrientationExperience />
            <InvestimentExperience />
            <QuestionsExperience questions={questions} program={"Experience Hunting"}/>
            <FooterOne />
        </>

    )
}

export default ExperienceHunting;